import Confetti from "react-confetti";
import "./App.css";
import { useState, useEffect } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

function App() {
  const { height, width } = useWindowDimensions();

  return (
    <div className="App">
      <h1>Happy Birthday, Rae!</h1>
      <Confetti width={width} height={height} />
    </div>
  );
}

export default App;
